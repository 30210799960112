<template>
  <section class="pt-4 mt-4">
    <div class="container article" v-if="typeof article.title != 'undefined'">
      <div class="row pt-3">
        <div
          class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12"
        >
          <h1>{{ article.title }}</h1>
          <h4 class="pb-2 text-muted">{{ article.sub }}</h4>
          <ImageCarouselModal :article="article" />
          <div
            class="show-zoom ratio ratio-16x9"
            data-bs-toggle="modal"
            data-bs-target="#imageCarouselModal"
          >
            <ImageCarousel :article="article" class="mb-4" />
          </div>

          <!-- Dit is de rechter helft van het linker gedeelte (1/2), alleen zichtbaar op xl en l schermen -->
          <!-- Hier komt de inhoud van de rechter helft -->
          <!-- <div v-html="article.excerpt" class="lead pb-4"></div> -->

          <!-- Content -->
          <div class="row border-top pt-4" v-if="article.content.length > 0">
            <div
              class="border-bottom mb-3"
              v-for="content in article.content"
              v-html="content"
              v-bind:key="content"
            ></div>
          </div>

          <div class="row mb-3 article_small" v-if="article.source.length > 0">
            <strong>Bronnen en Fotografie:</strong>
            <a
              v-for="source in article.source"
              v-bind:key="source"
              :to="source.source_link"
              class="text-secondary"
              target="_blank"
            >
              {{ source.source_text }}
            </a>
          </div>
          <div class="col-md-12 mb-5 article_small">
            <strong>Tags:</strong><br />
            <router-link
              v-for="(tag, index) in article.tags"
              v-bind:key="tag"
              :to="{ name: 'tag', params: { tag: tag } }"
              class="text-secondary"
            >
              {{ tag }}<span v-if="index < article.tags.length - 1">, </span>
            </router-link>
            <!-- <ShareButton :article="article" class="pt-3" /> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
// import ShareButton from "@/components/ShareButton.vue";
import ImageCarousel from "@/components/ImageCarousel.vue";
import ImageCarouselModal from "@/components/ImageCarouselModal.vue";

export default {
  name: "ArticleView",
  components: {
    // ShareButton,
    ImageCarousel,
    ImageCarouselModal,
  },
  computed: {
    hasArticleButtons() {
      if (this.article.buttons) {
        if (this.article.buttons.length > 0) {
          return true;
        }
      }
      return false;
    },
  },
  data: function () {
    return {
      loading: true,
      article: {},
    };
  },
  created() {
    this.fetchArticle();
  },
  methods: {
    fetchArticle() {
      // console.log(this.$route.params.slug);
      let token = process.env.VUE_APP_WEBTOKEN;
      let url = process.env.VUE_APP_COCKPITURL;
      axios
        .get(url + "/content/item/articles", {
          params: {
            limit: 1,
            filter: '{"slug": "' + this.$route.params.slug + '",}',
          },
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          console.log(response);
          this.article = response.data;
          document.title = "Koetshuis Sion - " + this.article.title;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  beforeRouteLeave(to, from, next) {
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.parentNode.removeChild(backdrop);
    }

    const modalElement = document.querySelector(".modal");
    if (modalElement) {
      modalElement.classList.add("modal-close");
      modalElement.classList.remove("modal-open");
    }

    // Remove .modal-open from body to re-enable scrolling
    document.body.classList.remove("modal-open");

    // Ensure scrolling is enabled
    document.body.style.overflow = "auto";

    next();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.show-zoom {
  cursor: zoom-in;
}
.article {
  font-size: 1.1rem;
}
.article_small {
  font-size: 1rem;
}
a {
  cursor: pointer;
}
</style>
