<template>
  <section class="p-4 mt-4">
    <div class="container" v-if="tag">
      <h1>
        Artikelen <small>({{ tag }})</small>
      </h1>
      <div class="row pt-3">
        <div
          class="col-lg-4 col-md-6 mb-4"
          v-for="article in getArticles"
          v-bind:key="article._id"
        >
          <ArticleCard :article="article" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ArticleCard from "@/components/ArticleCard.vue";

export default {
  name: "ArticlesView",
  components: {
    ArticleCard,
  },
  data: function () {
    return {
      tag: null,
    };
  },
  created() {
    this.$store.dispatch("resetPagination");
    this.fetchArticles();
  },
  computed: {
    getArticles() {
      return this.$store.getters.getArticles;
    },
  },
  methods: {
    fetchArticles() {
      this.tag = this.$route.params.tag;
      this.$store.commit(
        "setFilter",
        '{"tags": "' + this.tag + '", "_state": true}'
      );
      document.title = "Koetshuis Sion - Artikelen (" + this.tag + ")";
      this.$store.dispatch("fetchArticles");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  border-radius: 3px;
}
</style>
