<template>
  <nav class="navbar navbar-transparent navbar-expand-lg navbar-light bg-light">
    <div class="container p-3">
      <router-link class="navbar-brand" to="/">
        <img
          class="navbar-logo"
          alt="Vue logo"
          src="../assets/logo.png"
          width="150"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!-- <li class="nav-item"> -->
          <!-- <router-link class="nav-link active" aria-current="page" to="/"> -->
          <!-- <span class="gradient-text">Sephen de Vos</span> -->
          <!-- <span class="square">Square</span>
              <span class="anatomy">Anatomy</span> -->
          <!-- </router-link> -->
          <!-- </li> -->
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/artikelen">
              Artikelen
            </router-link>
          </li> -->
          <li
            class="nav-item"
            v-for="menu_item in menu_items_left"
            v-bind:key="menu_item"
          >
            <router-link class="nav-link" :to="menu_item.menu_item_link">
              {{ menu_item.menu_item_text }}
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li
            class="nav-item"
            v-for="menu_item in menu_items_right"
            v-bind:key="menu_item"
          >
            <router-link class="nav-link" :to="menu_item.menu_item_link">
              {{ menu_item.menu_item_text }}
            </router-link>
          </li>
        </ul>
        <form class="d-flex">
          <router-link class="nav-link me-2" to="/contact">
            <button class="btn btn-outline-primary" type="submit">
              Contact
            </button>
          </router-link>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  name: "NavBar",
  data: function () {
    return {
      loading: true,
      menu_items_right: [],
      menu_items_left: [],
    };
  },
  created() {
    this.fetchCategoryTags();
  },
  methods: {
    fetchCategoryTags() {
      this.loading = true;
      axios
        .get("https://cma.koetshuis-sion.nl/api/content/item/menuitems", {
          params: {},
          headers: {
            "api-key": "API-13955e30cf39231cb347d0361f353442bf24ddfa",
          },
        })
        .then((response) => {
          console.log(response);
          this.menu_items_right = response.data.menu_items_right;
          this.menu_items_left = response.data.menu_items_left;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  position: relative;
  top: 5px;
}
.navbar-brand {
  position: absolute;
  top: 0px;
  left: calc(50%);
  margin-left: -75px !important;
  display: block;
  z-index: 1000;
}
/* .navbar-brand {
  position: absolute;
  top: -20px;
  
  
  display: block;
  z-index: 1000;
} */
/* .navbar-logo {
  -webkit-filter: drop-shadow(0px 2px 10px #555);
  filter: drop-shadow(0px 2px 10px #555);
} */
.navbar-transparent {
  background: transparent !important;
}
</style>
