<template lang="html">
  <div
    class="modal fade"
    id="imageCarouselModal"
    tabindex="-1"
    aria-labelledby="imageCarouselModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <ImageCarousel :article="article" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCarousel from "@/components/ImageCarousel.vue";

export default {
  name: "ImageCarouselModal",
  components: {
    ImageCarousel,
  },
  props: {
    article: Object,
  },
};
</script>

<style lang="css" scoped></style>
