<template>
  <div class="home">
    <!-- <HomeFeatured /> -->
    <ArticleSection />
    <!-- <NewsletterSection /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HomeFeatured from "@/components/HomeFeatured.vue";
import ArticleSection from "@/components/ArticleSection.vue";
// import NewsletterSection from "@/components/NewsletterSection.vue";

export default {
  name: "HomeView",
  mounted() {
    document.title = "Koetshuis Sion";
  },
  components: {
    // HomeFeatured,
    ArticleSection,
    // NewsletterSection,
  },
};
</script>
