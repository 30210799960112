<template>
  <section class="p-4 mt-4">
    <div class="container">
      <div class="row pt-3">
        <div
          class="col-lg-4 col-md-6 mb-2"
          v-for="article in getArticles"
          v-bind:key="article._id"
        >
          <ArticleCard :article="article" />
        </div>
      </div>
      <!-- <router-link class="" :to="{ name: 'articles' }">
        <button
          type="button"
          class="btn btn-primary btn-lg px-4 me-md-2 w-100 align-text-bottom mt-5 text-white"
        >
          View More Articles
        </button>
      </router-link> -->
    </div>
  </section>
</template>

<script>
import ArticleCard from "@/components/ArticleCard.vue";

export default {
  name: "ArticleSection",
  components: {
    ArticleCard,
  },
  data: function () {
    return {};
  },
  created() {
    this.$store.dispatch("resetPagination");
    this.$store.commit("setFilter", '{"featured": true, "_state": true}');
    this.$store.dispatch("fetchArticles");
  },
  computed: {
    getArticles() {
      return this.$store.getters.getArticles;
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  border-radius: 3px;
}
</style>
