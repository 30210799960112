<template lang="html">
  <div
    id="carouselExampleControls"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div
        class="carousel-item"
        data-bs-interval="3000"
        v-for="(image, index) in article.image"
        v-bind:key="image"
        :class="{ active: index === 0 }"
      >
        <div class="image-container">
          <img
            :src="
              'https://cma.koetshuis-sion.nl/storage/uploads/' +
              image.path +
              '?modified=' +
              article._modified
            "
          />
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="prev"
      v-if="article.image.length > 1"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleControls"
      data-bs-slide="next"
      v-if="article.image.length > 1"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "ImageCarousel",
  props: {
    article: Object,
  },
};
</script>

<style lang="css" scoped>
.image-container {
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 aspect ratio */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.carousel {
  border-radius: 5px;
  overflow: hidden;
}
</style>
