import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
// import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    projects: [],
    filter: '{"_state": true}',
    totalItems: 0,
    limit: 12,
    page: 1,
    skip: 0,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getArticles(state) {
      return state.projects;
    },
    getFilter(state) {
      return state.filter;
    },
    getTotalItems(state) {
      return state.totalItems;
    },
    getLimit(state) {
      return state.limit;
    },
    getPage(state) {
      return state.page;
    },
    getSkip(state) {
      return state.skip;
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setArticles(state, projects) {
      state.projects = projects;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setTotalItems(state, totalItems) {
      state.totalItems = totalItems;
    },
    setLimit(state, limit) {
      state.limit = limit;
    },
    setPage(state, page) {
      state.page = page;
    },
    setSkip(state, skip) {
      state.skip = skip;
    },
  },
  actions: {
    fetchArticles(state) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_WEBTOKEN;
      axios
        .get("https://cma.koetshuis-sion.nl/api/content/items/articles", {
          params: {
            limit: state.getters.getLimit,
            skip: state.getters.getSkip,
            filter: state.getters.getFilter,
            sort: '{"release_date":-1 }',
          },
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          console.log(response);
          state.commit("setArticles", response.data.data);
          state.commit("setTotalItems", response.data.meta.total);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    resetPagination(state) {
      state.commit("setLimit", 12);
      state.commit("setPage", 1);
      state.commit("setSkip", 0);
    },
  },
  modules: {},
});
