<template lang="html">
  <div class="card article-card h-100">
    <router-link :to="{ name: 'article', params: { slug: article.slug } }">
      <div class="image-container">
        <img
          :src="
            'https://cma.koetshuis-sion.nl/storage/uploads/' +
            article.image[0].path +
            '?modified=' +
            article._modified
          "
        />
      </div>
      <div class="card-body">
        <h5 class="card-title">{{ article.title }}</h5>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ArticleCard",
  props: {
    article: Object,
  },
};
</script>

<style lang="css" scoped>
.card-title {
  /*color: #c4007c;*/
}
.article-card {
  border: none;
  background-color: transparent;
}
.article-card img {
  border-radius: 3px;
}
.article-card .card-body {
  padding: 5px;
}
.image-container {
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 aspect ratio */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
