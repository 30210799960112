<template>
  <section class="p-4 mt-4">
    <div class="container">
      <h1>Alle Artikelen</h1>
      <div class="row pt-3">
        <div
          class="col-lg-4 col-md-6 mb-4"
          v-for="article in getArticles"
          v-bind:key="article._id"
        >
          <ArticleCard :article="article" />
        </div>
        <PaginationNav />
      </div>
    </div>
  </section>
</template>

<script>
import ArticleCard from "@/components/ArticleCard.vue";
import PaginationNav from "@/components/PaginationNav.vue";

export default {
  name: "ArticlesView",
  components: {
    ArticleCard,
    PaginationNav,
  },
  mounted() {
    document.title = "Koetshuis Sion - Artikelen";
  },
  data: function () {
    return {};
  },
  created() {
    this.$store.dispatch("resetPagination");
    this.$store.commit("setFilter", '{"_state": true}');
    this.$store.dispatch("fetchArticles");
  },
  computed: {
    getArticles() {
      return this.$store.getters.getArticles;
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  border-radius: 3px;
}
</style>
